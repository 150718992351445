import React from 'react'
import { HistoryIcon, Button, useModal } from '@pancakeswap/uikit'
import styled from "styled-components";
import TransactionsModal from './TransactionsModal'

const RecentAct = styled.i`
  color: ${({theme}) => theme.colors.primary};
  width: 30px;
  font-family: "Px Icons";
  font-style: normal;
  font-size: 30px;
  &::before {
    content: "\\e92e"
  }
`

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <Button variant="text" p={0} onClick={onPresentTransactionsModal}>
        <RecentAct/>
      </Button>
    </>
  )
}

export default Transactions
