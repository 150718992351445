import React from 'react'
import { Button, NotificationDot, useModal } from '@pancakeswap/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import styled from "styled-components";
import SettingsModal from './SettingsModal'

const PxIcon = styled.span`
  font-family: "Px Icons";
  font-size: 30px;
  width: 30px;
`

export default function SettingsTab() {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [expertMode] = useExpertModeManager()

  return (
    <NotificationDot show={expertMode}>
      <Button variant="text" p={0} onClick={onPresentSettingsModal} id="open-settings-dialog-button">
          <PxIcon>&#xea66;</PxIcon>
      </Button>
    </NotificationDot>
  )
}
