import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'PXC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x0602493B9CA99690F7320b803409056d72E34F75',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'PXC-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x50657347E0FD44753C78e053EaaBF63a293c41aD',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x54458c2E2c1D6b1f267a279E79B7124fB6a69116',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'BNB-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xf9196D4765971773515e353Aa4313906F903926c',
    },
    token: tokens.wbnb,
    quoteToken: tokens.usdt,
  },
  {
    pid: 4,
    lpSymbol: 'PXT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3E534e01761a2302715682d84873939f528DcFf5',
    },
    token: tokens.pxt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'BUSD-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xe04928f93B0C179Bb2D4e1925B0CD8aFE3d2443a',
    },
    token: tokens.busd,
    quoteToken: tokens.usdt,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */

]

export default farms
