import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Swap'),
    icon: 'swap',
    href: '/swap',
  },
  {
    label: t('Liquidity'),
    icon: 'pool',
    href: '/liquidity',
  },
  {
    label: t('Farm'),
    icon: 'farm',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'stake',
    href: '/pools',
  },

  {
    label: t('DAO'),
    icon: 'dao',
    href: '/dao',
  },
  {
    label: t('Lottery'),
    icon: 'lottery',
    href: '/lottery',
  },
  {
    label: t('Bridge'),
    icon: 'bridge',
    href: '/bridge',
  },
]

export default config
