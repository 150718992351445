import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon } from '@pancakeswap/uikit'
import { Link } from 'react-router-dom'
import Settings from './Settings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const ControlContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  return (
    <AppHeaderContainer>
      {backTo && (
          <IconButton as={Link} to={backTo}>
            <ArrowBackIcon width="32px" />
          </IconButton>
      )}
      <ControlContainer>
        {!noConfig && (
            <Flex>
              <Settings />
            </Flex>
        )}
        <Flex alignItems="center">
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Heading as="h2" mb="8px">
              {title}
            </Heading>
            <Flex alignItems="center">
              {helper && <QuestionHelper text={helper} mr="4px" />}
              <Text color="textSubtle" fontSize="14px" textAlign="center">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {!noConfig && (
            <Flex>
              <Transactions />
            </Flex>
        )}
      </ControlContainer>
    </AppHeaderContainer>
  )
}

export default AppHeader
